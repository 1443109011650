import styled from "styled-components"

import { breakpoint } from "src/constants/breakpoints"
import { Breadcrumbs, IBreadcrumb } from "src/ui/Breadcrumbs/Breadcrumbs"
import { TextButton } from "src/ui/Button/TextButton"
import { colorsLegacy } from "src/ui/colors"
import { CopyText } from "src/ui/CopyText/CopyText"
import { Divider } from "src/ui/Divider/Divider"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function ParadiseDetailsLayout({
  meta,
  actions,
  left,
  right,
  banner,
  breadcrumbs,
  breadcrumbCopyValue,
}: {
  meta: React.ReactNode
  actions?: React.ReactNode
  left: React.ReactNode
  right: React.ReactNode
  banner?: React.ReactNode
  breadcrumbs: IBreadcrumb[]
  breadcrumbCopyValue?: string
}) {
  return (
    <DetailsWrapper>
      <TopSection>
        {banner && <BannerWrapper>{banner}</BannerWrapper>}
        <TopMetaWrapper>
          <MetaWrapper>
            <BreadcrumbWrapper>
              <Breadcrumbs items={breadcrumbs} />
              {breadcrumbCopyValue && (
                <CopyText
                  value={breadcrumbCopyValue}
                  copiedText="Copied"
                  width={18}
                />
              )}
            </BreadcrumbWrapper>
            {meta}
          </MetaWrapper>
          {actions}
        </TopMetaWrapper>
      </TopSection>
      <Divider />
      <BottomSection>
        <LeftSection>
          <DetailsInnerWrapper>{left}</DetailsInnerWrapper>
        </LeftSection>
        <RightSection>
          <DetailsInnerWrapper>{right}</DetailsInnerWrapper>
        </RightSection>
      </BottomSection>
    </DetailsWrapper>
  )
}

export function ParadiseDetailsSection({
  title,
  buttonLabel,
  onButtonClick,
}: {
  title: React.ReactNode
  buttonLabel?: string
  onButtonClick?: () => void
}) {
  return (
    <SectionWrapper>
      <MText variant="subtitle">{title}</MText>
      {buttonLabel && (
        <TextButton onClick={onButtonClick}>{buttonLabel}</TextButton>
      )}
    </SectionWrapper>
  )
}

const SectionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const DetailsWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const MetaWrapper = styled.div`
  display: grid;
  gap: ${spacing.XS};
`

const TopSection = styled.div`
  padding-block: ${spacing.S};
  padding-inline: ${spacing.L};

  background-color: ${colorsLegacy.backgroundGrayV3};
`

const BannerWrapper = styled.div`
  margin-bottom: ${spacing.L};
`

const BreadcrumbWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS};
`

const TopMetaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: ${spacing.M};

  @media (${breakpoint.normalUp}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
  }
`

const BottomSection = styled.div`
  flex: 1;
  @media (${breakpoint.normalUp}) {
    display: flex;
  }
`

const LeftSection = styled.div`
  padding-inline: ${spacing.L};
  padding-block: ${spacing.L};

  @media (${breakpoint.normalUp}) {
    width: 30%;
    border-inline-end: 1px solid ${colorsLegacy.divider};
    margin-block-end: 0;
    overflow-y: auto;
    padding-bottom: ${spacing.M};
  }
`

const RightSection = styled.div`
  padding-inline: ${spacing.L};
  padding-block: ${spacing.L};
  @media (${breakpoint.normalUp}) {
    width: 70%;
    overflow-y: auto;
  }
`

const DetailsInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.M};
`
