import { useState } from "react"
import styled from "styled-components"

import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { colorsLegacy } from "src/ui/colors"
import { MDialog } from "src/ui/Dialog/MDialog"
import { JsonTree } from "src/ui/JsonTree/JsonTree"
import { spacing } from "src/ui/spacing"

export function ParadiseOverviewExpandable({
  children,
  rawJson,
}: {
  children: React.ReactNode
  rawJson: object
}) {
  const [showRawDataDialog, setShowRawDataDialog] = useState(false)

  return (
    <div>
      <ParadiseDetailsSection
        title="Overview"
        buttonLabel="Raw data"
        onButtonClick={() => setShowRawDataDialog(true)}
      />

      <OverviewWrapper>{children}</OverviewWrapper>

      <MDialog
        title="Raw user data"
        open={showRawDataDialog}
        maxWidth="md"
        showCancel={false}
        onConfirm={() => setShowRawDataDialog(false)}
        confirmLabel="Close"
        onClose={() => setShowRawDataDialog(false)}
      >
        <JsonTree json={rawJson} />
      </MDialog>
    </div>
  )
}

const OverviewWrapper = styled.div`
  display: grid;
  gap: ${spacing.M};
  padding-block: ${spacing.S};
  font-size: 0.875rem;

  & > * {
    border-bottom: 1px solid ${colorsLegacy.divider};
    padding-bottom: ${spacing.XS2};
  }
`
